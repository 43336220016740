<template>
  <section class="users-bot-page">
    <r-select
      class="mb-6"
      label="Статус пользователей"
      :items="typesUser"
      v-model="isEnabled"
    />
    <r-spinner
      size="nelida"
      class="users-bot-page--loading"
      v-if="isLoading"
    />
    <div
      class="users-bot-page__list"
      v-else
    >
      <users-bot-item
        v-for="executor in executors"
        :key="executor.id"
        :executor="executor"
        :isEnabled="isEnabled"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import UsersBotItem from '@/views/usersBot/components/UsersBotItem';

export default {
  name: 'UsersBotPage',
  components: { UsersBotItem },
  data: () => ({
    isLoading: false,
    typesUser: [
      {
        id: '1',
        title: 'Активные'
      },
      {
        id: '0',
        title: 'Неактивные'
      }
    ],
    isEnabled: '1'
  }),
  computed: {
    ...mapState('executors', ['executors'])
  },
  watch: {
    async isEnabled() {
      await this.getData();
    }
  },
  async mounted() {
    this.setProperty({ name: 'titlePage', title: 'Исполнители соц. сети' });
    await this.getData();
  },
  methods: {
    ...mapActions('executors', ['getUsersBot']),
    ...mapMutations('apps', ['setProperty']),
    async getData() {
      this.isLoading = true;
      await this.getUsersBot({
        enabled: this.isEnabled
      });
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.users-bot-page{
  position: relative;
  //padding: 0 32px;
  &--loading{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &__list{
    display: flex;
    flex-direction: column;
  }
}
</style>
