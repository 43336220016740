// import library from '@pg/r-ui/src/RirUI'
/* import library from '@/plugins/ui/RirUI.umd.min'
import('@/plugins/ui/RirUI.css')
export default {
  async install (Vue) {
    const { Components, Directives, Mixins } = library
    Object.keys(Components).forEach((name) => {
      Vue.component(Components[name].name, Components[name])
    })
    Object.entries(Directives).forEach(([key, value]) => {
      Vue.directive(key, value)
    })
    Object.values(Mixins).forEach((mixin) => {
      Vue.mixin(mixin)
    })
  }
}
*/
import Vue from 'vue';
import Rir from '@rir/vue-library';
//import '@rir/vue-library/dist/RirLib.css';
import './vue-library/RirLib.css';
Vue.use(Rir);

const options = {};
export default new Rir(options);
